import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import Header from './header';
import Footer from './footer';
import useSiteMetadata from '../hooks/use-sitemetadata';

const LayoutEnglish = ({ children }) => {
  const { title, description } = useSiteMetadata();
  const [isEnglish, setLang] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const href = window.location.href.split('/');
      setLang(href.includes('en'), []);
    }
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="content" description={description}></meta>
      </Helmet>
      <Header isEnglish={isEnglish} />

      <main>{children}</main>
      <Footer isEnglish={isEnglish} />
    </>
  );
};

export default LayoutEnglish;
