import React from 'react';
import LayoutEnglish from './../../components/layout-en';

const History = () => (
  <LayoutEnglish>
    <section className="max-w-3xl mx-auto px-4 py-16">
      <h1 className="text-4xl font-extrabold text-teal-700 mb-4">
        Our History
      </h1>
      <p className="text-base md:text-xl text-gray-800 mb-4">
        INDUBA S.R.L. is a family business in the argo-forestry industry, that
        was founded and developed in Argentina since 1992.
      </p>
      <p className="text-base md:text-xl text-gray-800 mb-4">
        Composed of 5 members, the marriage of the agro-forestal engineer Raúl
        Badaracco and Inés Fernández Dos Sants, and their children Guillermo and
        Tulio, who maintain the business of Induba, and Marcelo, who is in
        charge of the agro-forestry division.
      </p>
      <p className="text-base md:text-xl text-gray-800 mb-4">
        Today, INDUBA S.R.L. employs 110 people. 90 in fabrication and 20 in
        argo-forestry. We maintain 1100 hectares of pruned pines, with the
        purpose of achieving complete industrial self-sufficiency and growth to
        2000 hectares.
      </p>
      {/* <p className="text-base md:text-xl text-gray-800 mb-4">
        Todo ello, bajo las normas internacionales certificadas por el F.S.C.
        (Forest Stewardship Council o Consejo de Administración Forestal),
        logrando de esta manera un manejo sustentable en todos lo sectores, una
        gestión forestal ambientalmente apropiada, socialmente beneficiosa y
        económicamente viable.
      </p> */}
      <p className="text-base md:text-xl text-gray-800">
        Including internal commerce, INDUBA S.R.L. exports to the United States,
        England, Ireland, Belgium, Germany, Italy, Australia, and New Zealand.
        Our objective is to consolidate our existing international exports and
        expand to additional markets in the near future.
      </p>
    </section>
  </LayoutEnglish>
);

export default History;
